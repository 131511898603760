import {KEY_SESSION_STORAGE_MAIN_LOGO_IS_LOADING, KEY_TIME_OUT_MAIN_LOGO_IS_LOADING} from "./Constants";

export function checkAnimateLogo() {
    let timeIsLoading = sessionStorage.getItem(KEY_SESSION_STORAGE_MAIN_LOGO_IS_LOADING);
    if (timeIsLoading) {
        let currentDate = new Date();
        return (Math.floor(currentDate.getTime() / 1000) - parseInt(timeIsLoading)) >= KEY_TIME_OUT_MAIN_LOGO_IS_LOADING;
    }
    return true;
}

export function setAnimateLogo() {
    let currentDate = new Date();
    sessionStorage.setItem(KEY_SESSION_STORAGE_MAIN_LOGO_IS_LOADING, "" + Math.floor(currentDate.getTime() / 1000));
}