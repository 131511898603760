import React from 'react';
import "../../styles/sass/AnimateLogo.sass";

export default class AnimateLogo extends React.Component
{
    render()
    {
        return (
            <div className={"MainAnimateLogoContainer"}>
                <div className={"InnerAnimateLogoContainer"}>
                    <img alt={"logo"} className={"inner_company_logo"} src={"/images/components_logo/inner_company_logo.png"} />
                    <img alt={"logo"} className={"inner_cogs_company_logo"} src={"/images/components_logo/inner_cogs_company_logo.png"} />
                    <img alt={"logo"} className={"outside_cogs_company_logo"} src={"/images/components_logo/outside_cogs_company_logo.png"} />
                </div>
            </div>
        )
    }
}
