import React from 'react';
import "../../styles/sass/ButtonsComponent.sass";
import {checkAnimateLogo, setAnimateLogo} from "../CheckAnimateLogo";

const DomMain = ".barenad.ru";
const Buttons = [
    {
        href: "products",
        content: "Доска товаров",
        description: "Некий автономный онлайн магазин без корзины товаров."
    },
    {
        href: "strike-or-die",
        content: "Игра \"Strike or Die\"",
        description: "Игра \"Strike or Die\""
    },
    {
        href: "solution-game-in-eight",
        content: "Решение игры \"Пятнашки\"",
        description: "Программа, которая найдёт все ходы от заданной матрицы до искомой!"
    },
    {
        href: "old-elder-world",
        content: "Старая версия редактора карт и игры  \"Elder World\"",
        description: "Игра и редактор карт \"Elder World!\""
    },
    // {
    //     href: "map-reader-elder-world",
    //     content: "Редактор карт \"Elder World\"",
    //     description: "Онлайн редактор карт для игры \"Elder World!\""
    // },
    // {
    //     href: "game-elder-world",
    //     content: "Игра \"Elder World\"",
    //     description: "Игра \"Elder World!\""
    // },
    // {
    //     href: "notes",
    //     content: "заметки",
    //     description: "Проект \"заметки\""
    // },
];

export default class ButtonsComponent extends React.Component
{
    view_all_buttons()
    {
        if (checkAnimateLogo()) {
            for (let i = 0; i < Buttons.length; i++) {
                setTimeout(() => {
                    document.getElementById("ButtonID::" + i).classList.remove("hidden");
                }, i * 200,);
            }
            setAnimateLogo();
        }
    }

    componentDidMount()
    {
        setTimeout(() => {this.view_all_buttons()}, 2500);
    }

    generated_button_grid()
    {
        let ButtonsGrid = [];
        let Count = 0;
        let classListButtonContainer = "ButtonContainer";
        if (checkAnimateLogo()) {
            classListButtonContainer += " hidden";
        }
        for (let i=0; (i < 5 && Count < Buttons.length); i++) {
            let Limited = 2;
            let AroundClass = " Around";
            if (i === 0 || i === 4){
                Limited = 5;
                AroundClass = "";
            }
            let ButtonsRow = [];
            for(let j = 0; (j < Limited && Count < Buttons.length); j++){
                let FullLink = "http://"+Buttons[Count].href + DomMain;
                ButtonsRow.push(
                    <div
                        id={"ButtonID::"+Count}
                        className={classListButtonContainer}
                        key={"InnerButtonContainerRID::"+i+"CID::"+j}
                    >
                        <button
                            onClick={() => {window.location.href = FullLink;}}
                            title={Buttons[Count].description}
                            className={"Button"}
                        >
                            <div className={"InnerBlockButton"}>
                                {Buttons[Count].content}
                            </div>
                        </button>
                    </div>
                );
                Count++;
            }
            ButtonsGrid.push(
                <div
                    key={"InnerButtonRowID::"+i}
                    className={"InnerRowContainer" + AroundClass}
                >
                    {ButtonsRow}
                </div>
            );
        }

        return ButtonsGrid;
    }

    render()
    {
        return (
            <div className={"MainButtonsContainer"}>
                {this.generated_button_grid()}
            </div>
        )
    }
}
