import React from 'react';
import "../../styles/sass/MainComponent.sass";
import AnimateLogo from "./AnimateLogo";
import ButtonsComponent from "./ButtonsComponent";
import {checkAnimateLogo} from "../CheckAnimateLogo";

export default class MainComponent extends React.Component
{
    componentDidMount()
    {
        if (checkAnimateLogo()) {
            setTimeout(() => {
                document.getElementById("MainLogoContainerID").classList.remove("hidden");
            }, 100);
        }
    }

    render()
    {
        let classListForMainLogo = "InnerContainerLogo";
        if (checkAnimateLogo()) {
            classListForMainLogo += " hidden";
        }
        return (
            <div className={"MainContainer"}>
                <div className={"BlockContainer"}>
                </div>
                <div id={"MainLogoContainerID"} className={classListForMainLogo}>
                    <AnimateLogo />
                </div>
                <ButtonsComponent />
            </div>
        )
    }
}
